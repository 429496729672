* {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    text-decoration: none;
    font-style: normal;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    margin: 0;
    padding: 0;
}

:before,
:after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

body,
html {
    font-family: 'Poppins';
    font-size: 14px;
    line-height: 1.78em;
    color: #000;
    font-weight: normal;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
}

/* Lists==================== */
ol,
ul {
    list-style: none;
}

/* Links, inputs and Buttons==================== */
a {
    color: inherit;
    display: inline-flex;
    align-items: center;
}

a,
.btn,
.button,
button,
input[type="button"],
input[type="button"],
input[type="submit"],
input[type="submit"],
select {
    outline: none;
    -webkit-transition: all cubic-bezier(1, -0.05, 0.25, 1) 0.4s;
    -o-transition: all cubic-bezier(1, -0.05, 0.25, 1) 0.4s;
    transition: all cubic-bezier(1, -0.05, 0.25, 1) 0.4s;
    cursor: pointer;
}

input,
textarea,
select {
    vertical-align: middle;
    background-color: transparent;
    border: 1px solid #ccc;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

select {
    -webkit-appearance: none;
}

input::-ms-clear,
input:-ms-clear {
    display: none;
}

textarea {
    resize: none;
}

:disabled,
::disabled {
    cursor: not-allowed;
}

:readonly,
::readonly {
    cursor: default;
}

::-webkit-input-placeholder {
    font-size: inherit;
    color: #bbb9d1;
}

::-moz-placeholder {
    font-size: inherit;
    color: #bbb9d1;
}

:-ms-input-placeholder {
    font-size: inherit;
    color: #bbb9d1;
}

::-ms-input-placeholder {
    font-size: inherit;
    color: #bbb9d1;
}

::placeholder {
    font-size: inherit;
    color: #bbb9d1;
}

/* Tables==================== */
.table,
table {
    display: table;
    table-layout: fixed;
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
}

td {
    vertical-align: middle;
}

/* Headings==================== */

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}

h1 {
    font-size: 4.21em;
    line-height: 75px;
}
h3 {
    font-size: 3.48em;
    line-height: 58px;
}

/* Other elements==================== */
img {
    display: inline-flex;
    max-height: 100%;
    max-width: 100%;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
    content: none;
}

br,
.clear {
    clear: both;
}

audio,
video {
    display: block;
    width: 100%;
}

figure {
    vertical-align: middle;
}

.btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: none;
}

.container {
    width: 100%;
    max-width: 106.71em;
    padding: 0 1.07em;
    margin: 0 auto;
}

.clearfix:after {
    clear: both;
}

.clearfix:after,
.clearfix:before {
    content: "";
    display: table;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.fz-0 {
    font-size: 0;
}

.d-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
}

.d-inline-flex {
    display: inline-flex;
    align-items: center;
}

.f-column {
    flex-direction: column;
    align-items: stretch;
}

.m-l {
    margin-left: auto;
}

.m-b {
    margin-bottom: auto;
}

.m-t {
    margin-top: auto;
}

.m-r {
    margin-right: auto;
}

.f-1 {
    flex: 1 1 auto;
}

.f-0 {
    flex: 0 0 auto;
}

.w-100 {
    width: 100%;
}

.h-100 {
    height: 100%;
}

.f-align-center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.f-justify-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.f-justify-between {
    justify-content: space-between;
}

.main {
    height: 100vh;
    overflow: hidden;
}
span.conditioWraper {
  display: contents;
}

.approvedSuccess {
  text-align: center;
  margin-top: 10px;
  margin-bottom: -25px;
}
.approvedSuccess.otherWrapings {
  cursor: pointer;
}

.muMoneyModal.show-modal {
  z-index: 9;
}

.muMoneyModal.show-modal .modal-content {
  padding: 20px 39px 40px 39px;
  overflow: hidden;
}

/* .modalContentSecondCondition.muMoneyConvertion {
  position: relative;
} */

.whiteListWraper {
  position: absolute;
  left: 0;
  height: 100%;
  top: 0;
  width: 100%;
  /* opacity: 0.5; */
}
.whiteListInnerWraper {
  width: 100%;
  height: 100%;
  position: relative;
  margin: auto;
}
.whiteListBoxInfo {
  position: absolute;
  width: 367px;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px 40px;
  border-radius: 10px;
  z-index: 99;
}
.whiteLabelText p {
  letter-spacing: 1.12px;
  opacity: 1;
  font-weight: normal;
  font-size: 16px;
  margin: 20px 0 30px 0;
}

.card footer a {
  width: 100%;
}



.stableCoinRow {
  width: 100%;
  float: left;
}



.indStableCoinsWraper:nth-child(4), .indStableCoinsWraper:nth-child(8), .indStableCoinsWraper:nth-child(12), .indStableCoinsWraper:nth-child(16), .indStableCoinsWraper:nth-child(20) .indStableCoinsWraper:nth-child(24), .indStableCoinsWraper:nth-child(28), .indStableCoinsWraper:nth-child(32), .indStableCoinsWraper:nth-child(36), .indStableCoinsWraper:nth-child(40), .indStableCoinsWraper:nth-child(44), .indStableCoinsWraper:nth-child(48), .indStableCoinsWraper:nth-child(52), .indStableCoinsWraper:nth-child(56), .indStableCoinsWraper:nth-child(60), .indStableCoinsWraper:nth-child(64)  {
  margin-right: 0;
}

.stablecoinInfo {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.stablecoinInfo img {
  width: 20px;
  margin-right: 5px;
}
.indStableCoinsWraper h4 {
  text-align: left;
  font: normal normal normal 16px/25px Poppins;
  letter-spacing: 0.64px;
  opacity: 1;
  font-weight: normal;
}

.stablecoinInfo p {
  text-align: left;
  font: normal normal normal 12px/18px Poppins;
  letter-spacing: 0.48px;
  opacity: 1;
}

.indStableCoinsWraper {  
  border-radius: 20px;
  opacity: 1;
  width: 123px;
  height: 63px;
  float: left;
  padding: 7px 15px 10px 15px;
  margin-right: 10px;
  margin-bottom: 20px;
  cursor: pointer;
}


.conversionRate.muMoneyModalHeading {
  text-align: left;
  font: normal normal normal 16px/25px Poppins;
  letter-spacing: 0.64px;
  opacity: 1;
  font-weight: normal;
  margin-bottom: 30px;
}

.modalSubHedading.muMoneyModal .wrapUnwrap {
  text-align: left;
  font: normal normal normal 16px/25px Poppins;
  letter-spacing: 0.64px;
  opacity: 1;
}
.modalSubHedading.muMoneyModal .balanceRate {
  text-align: left;
  font: normal normal normal 16px/25px Poppins;
  letter-spacing: 0.64px;
  opacity: 1;
}

.miningInfo p {
  text-align: center;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0px;
  }
  
  .miningInfo p span {
    color: #0DB807;
  }

  .muMoneyRedeem {
    margin-top: 0;
  }
  .redeemWrap {
    margin-bottom: 20px;
  }
  .redeemMaxAmt {
    text-align: right;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0px;
    opacity: 1;
    margin-left: 10px;
  }
  .redeemBtns {
    width: 118px;
    padding: 10px;
    font-size: 14px;
    display: flex;
    align-items: center;
  }

  .currencyIcon {
    padding-right: 10px;
    width: 124px;
  }
  