
.main {
  background-color: #000b2c;
  background-image: url("../images/mu-bg.png"), url("../images/bgPattern.png"),
    url("../images/bgPattern2.png");
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-position: left center, right 54.9375em top, right 0 top 5em;
  background-size: 19.625em, 65.0625em 34.375em, 28.25em 42.25em;
}
.footer {
  background-color: #151f3e;
}
.btn-theme-dark {
  background: #fff;
}
.btn-dark {
  background-color: #151f3e;
  color: #fff;
}
.btn-dark:hover {
  background: rgb(75 32 147 / 48%);
}
.header-links ul li a {
  color: #ffffff;
}

.copyText p {
  color: #9099b5;
}
.copyText p a {
  color: #fff;
}
.headerMain {
  color: #fff;
}
.muAmtComp h5 {
  color: #fff;
}
.muAmtComp span {
  color: #d6d6d6;
}
.footerLink li a:hover *[fill="#fff"] {
  transition: all cubic-bezier(0.165, 0.84, 0.44, 1) 0.4s;
  fill: #ff1aa1;
}
.footerLink li a:hover *[stroke="#fff"] {
  transition: all cubic-bezier(0.165, 0.84, 0.44, 1) 0.4s;
  stroke: #ff1aa1;
}
.header-options .btn-double {
  background: #000b2c;
  color: #ffffff;
  border: 1px solid #ffd700;
  padding-top: 3px;
}
.header-options .btn-double .bottomDeck {
  border-color: #ffd700;
}
.currentValueDropdown {
  width: 350px;
  background: #000b2c;
  box-shadow: 0px 12px 20px #00000061;
  border: 1px solid #ffd700;
  opacity: 1;
  border-radius: 5px;
  position: absolute;
  right: 0;
  top: 115%;
  z-index: 9;
}

.currentValueDropdown.displayDropdown {
  display: block;
}

.noBalance.currentValueDropdown p {
  color: #fff;
  font-size: 1em;
  margin-bottom: 5px;
  text-align: center;
}

.noBalance.currentValueDropdown h5 {
  font-size: 1.25em;
  color: #ffd700;
  text-align: center;
}

.balanceInfoLi h5 {
  color: #fff;
  font-size: 1em;
}

.balanceInfoLi span {
  color: #d6d6d6;
  font-size: 1em;
}

.balanceInfoLi span.valuationSpan {
  font-size: 1em;
  color: #fff;
}
.balanceInfoLi span.valueSpan {
  font-size: 0.85em;
}

.individualListingLi:hover {
  background: rgb(18 30 86 / 68%);
}

.individualListingLi {
  border-bottom: 1px solid #2f3851;
}
.totalValue {
  color: #d6d6d6;
}
.totalValuation {
  color: #fff;
}
.disconnectedBtns {
  background: #151f3e;
  color: #fff;
}

.disconnectedBtns:hover {
  background: rgb(41 60 116 / 56%);
}

.muAmtComp {
  background: transparent
    linear-gradient(270deg, #1722437a 0%, var(--unnamed-color-d2d2d2) 100%) 0%
    0% no-repeat padding-box;
  background: transparent
    linear-gradient(270deg, #282b4d 0%, rgba(83, 87, 111, 0.2) 100%) 0% 0%
    no-repeat padding-box;
}

.muGold .muAmtComp {
  background: transparent
    linear-gradient(270deg, #1722437a 0%, rgba(255, 217, 0, 0.3) 100%) 0% 0%
    no-repeat padding-box;
}
.muOre .muAmtComp {
  background: transparent
    linear-gradient(270deg, #1722437a 0%, var(--unnamed-color-ffbfa2) 100%) 0%
    0% no-repeat padding-box;
  background: transparent
    linear-gradient(270deg, #1722437a 0%, rgba(255, 191, 162, 0.3) 100%) 0% 0%
    no-repeat padding-box;
}
.muVault .muAmtComp {
  background: transparent
    linear-gradient(270deg, #1722437a 0%, var(--unnamed-color-9099b5) 100%) 0%
    0% no-repeat padding-box;
  background: transparent linear-gradient(270deg, #1722437a 0%, #9099b5 100%) 0%
    0% no-repeat padding-box;
}

.secondChild span {
  color: #d6d6d6;
}

.goDark {
  display: none;
}

/* Modal Css Start */

.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0 11 44 / 90%);
  opacity: 0;
  visibility: hidden;
  transform: scale(1.1);
  transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
}

.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px 39px 40px 39px;
  width: 43.17em;
  background: transparent linear-gradient(128deg, #e2e0e066 0%, #e2e0e00d 100%)
    0% 0% no-repeat padding-box;
  border: 1px solid #e2e0e099;
  border-radius: 10px;
  opacity: 1;
  backdrop-filter: blur(40px);
  -webkit-backdrop-filter: blur(40px);
}

.show-modal {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
  transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
}

.conversionRate {
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
  cursor: pointer;
}

.wrapUnwrap {
  font-size: 20px;
  color: #9099b5;
}

.balanceRate {
  font-size: 16px;
  color: #9099b5;
  margin-left: auto;
}

.inputFieldSection input {
  color: #fff;
}

.swapBtn {
  /* background: transparent linear-gradient(90deg, #BC00FF 0%, #FF1AA1 100%) 0% 0% no-repeat padding-box;
box-shadow: 0px 10px 30px #94006733; */
  border-radius: 25px;
  opacity: 1;
  width: 100%;
  font-size: 1em;
  padding: 1.07em 0;
  border: none;
  outline: none;
}

.buttonDiv {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 40px;
}

.maxButton.halfBtn {
  width: 49%;
  margin-right: auto;
  padding: 1.07em 0;
}

.swapBtn.btnHalf {
  width: 49%;
  margin-left: auto;
}

.disabledBtn {
  background: #151f3e;
  cursor: none;
  color: #9099b5;
}
.modal-content.succesFailResult {
  background-image: url("../images/success.png");
}

.firstConvertRow {
  display: flex;
  align-items: center;
  background: #283250 0% 0% no-repeat padding-box;
  border: 1px solid #9099b5;
  border-radius: 42px;
  opacity: 1;
  padding: 0.85em 1.01em;
  height: 54px;
}

.valueBtn {
  background: #151f3e;
}
.valueBtn:hover {
  background: rgb(18 30 86 / 68%);
}
/* Modal Css End */

.maxButton.btn-gradient.halfBtn,
.swapBtn.btn-gradient.approved.btnHalf {
  background: transparent linear-gradient(90deg, #bc00ff 0%, #ff1aa1 100%) 0% 0%
    no-repeat padding-box;
  box-shadow: 0 0.7em 2.14em #94006733;
  color: #fff;
}
.swapBtn.btn-gradient.disabled.btnHalf,
.maxButton.btn-disabled.halfBtn {
  background: #151f3e 0% 0% no-repeat padding-box;
  color: var(--unnamed-color-9099b5);
  cursor: not-allowed;
  color: #fff;
  opacity: 0.45;
  box-shadow: 0 0.7em 2.14em #02000133;
}
.approvedSuccess {
  color: #ebeaea;
}

.approvingSuccess {
  color: #fff;
}

.muGoldValues span {
  color: #fff;
}

.muGoldValues {
  background: transparent linear-gradient(269deg, #a5a399 0%, #bf99b2 100%) 0%
    0% no-repeat padding-box;
}

/* Mu Money Css */

.conversionRate.muMoneyModalHeading {
  color: #9099b5;
}

.modalSubHedading.muMoneyModal .wrapUnwrap {
  color: #ffffff;
}
.modalSubHedading.muMoneyModal .balanceRate {
  color: #ffffff;
}

.indStableCoinsWraper {
  background: #2a3148 0% 0% no-repeat padding-box;
  border: 1px solid transparent;
}
.indStableCoinsWraper:hover,
.indStableCoinsWraper.activated {
  border: 1px solid #ff1aa1;
}

.whiteLabelText p {
  color: #ffffff;
}

.stablecoinInfo p {
  color: #d6d6d6;
}

.indStableCoinsWraper h4 {
  color: #ffffff;
}

.whiteListWraper {
  background: rgb(0 11 44 / 50%);
}
.whiteListBoxInfo {
  background: #000b2c 0% 0% no-repeat padding-box;
  box-shadow: 0px 12px 20px #00000061;
  border: 1px solid #9595a4;
}

.whiteListIcon .liteAlert {
  display: none;
}

.whiteListIcon .darkAlert {
  display: block;
  margin: 0 auto;
}

.miningInfo.MuCoins {
  background: transparent linear-gradient(269deg, #252c48 0%, #d2d2d2 100%) 0%
    0% no-repeat padding-box;
}

.miningInfo.MuGold {
  background: transparent linear-gradient(269deg, #252c48 0%, #f4d974 100%) 0%
    0% no-repeat padding-box;
}

.miningInfo.MuOre {
  background: transparent linear-gradient(269deg, #252c48 0%, #ffbfa2 100%) 0%
    0% no-repeat padding-box;
}

.miningInfo.MuVault {
  background: transparent linear-gradient(269deg, #32345a 0%, #9099b5 100%) 0%
    0% no-repeat padding-box;
}

.miningInfo p {
  color: #ffffff;
}

.redeemMaxAmt {
  color: #ffffff;
}

.redeemBtns {
  color: #505a7b;
}

.currencyIcon {
  border-right: 1px solid #505a7b;
}

.redeemBtns {
  color: #fff;
}

.timerLg {
  padding: 0 50px;
  /* z-index: 4; */
  position: relative;
  justify-content: center;
  width: 93%;
  align-items: center;
  grid-gap: 15px;
  gap: 15px;
  margin: 4.6rem auto 0;
}

.timerLg .liquidity {
  background: transparent linear-gradient(89deg, #bc00ff 0%, #ff1aa1 100%) 0% 0%
    no-repeat padding-box;
  width: fit-content;
  color: white;
  padding: 13px 0;
  border-radius: 120px;
  font-size: 14px;
  font-weight: 300 !important;
  letter-spacing: 1px;
  width: 12.5rem;
  height: 50px;
  text-align: center;
}
.timerLg .remainTime {
  margin-top: 5px;
}
.timerLg .remainTime > p {
  color: white;
  font-size: 17px;
  padding-bottom: 4px;
  display: flex;
  align-items: center;
  gap: 5px;
}
.timerLg .remainTime p img {
  cursor: pointer;
}
.timerLg .remainTime > div {
  gap: 8px;
  align-items: baseline;
}
.timerLg .remainTime span {
  color: white;
}
.timerLg .remainTime > div .timeSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  color: white;
}
.timerLg .remainTime > div .timeSection p {
  font-size: 15px;
}
.timerLg .remainTime > div .timeSection .timeBox {
  background-color: #151f3d;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}
.timerLg .progress {
  background-color: #151f3e;
  border-radius: 35px;
  padding: 10px 0px;
  width: 100%;
  /* height: 70px; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.timerLg .main_progress_sect {
  background-color: #151f3e;
  border-radius: 20px;
  padding: 10px 20px;
  width: 65%;
  height: 86px;
  /* display: flex;
  align-items: center;
  justify-content: center; */
}
.timerLg .main_progress_sect .time_completed {
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.timerLg .main_progress_sect .time_completed p > p {
  color: #9099b5;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 5px;
}
.timerLg .main_progress_sect .time_completed div:first-of-type img {
  cursor: pointer;
  position: relative;
}

.timerLg .main_progress_sect .time_completed div:last-of-type span {
  color: #fff;
  font-size: 15px;
  font-weight: 400;
}
.timerLg .progress span {
  color: white;
}
.timerLg .progress .progress-bar__wrapper {
  position: relative;
  width: 100%;
  margin-bottom: -4px;
  margin-right: 10px;
}

.timerLg .progress progress {
  width: 100%;
  height: 20px;
  border-radius: 50px;
  /* background-color: #e2e0e066; */
  background: transparent linear-gradient(92deg, #e2e0e066 0%, #e2e0e00d 100%)
    0% 0% no-repeat padding-box;
  transition: width 300ms ease;
}
.timerLg .progress progress[value]::-webkit-progress-bar {
  width: 100%;
  height: 20px;
  border-radius: 50px;
  background: transparent linear-gradient(92deg, #e2e0e066 0%, #e2e0e00d 100%)
    0% 0% no-repeat padding-box;
  transition: width 300ms ease;
}
.timerLg .progress progress[value]::-webkit-progress-value {
  border-radius: 100px;
  background: transparent linear-gradient(180deg, #bc00ff 0%, #ff1aa1 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 0px 14px #f817ab;
  transition: width 300ms ease;
}
