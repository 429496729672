@media only screen and (max-width: 1550px) {
  .header {
    padding: 1.1em 0 0 0;
  }
  .header-links {
    margin-left: 10.85em;
  }
  .header-links ul li:not(:last-child) {
    margin-right: 3em;
  }
  .header-links ul li a {
    font-size: 1.15em;
  }
}
@media only screen and (max-width: 1450px) {
  .mucards {
    margin-top: 5.2em;
    column-gap: 2.02em;
  }

  .contentMain {
    padding-top: 1.55em;
    padding-bottom: 1.55em;
  }
  .header-options .btn {
    min-height: 3.57em !important;
  }
}

@media only screen and (max-width: 1350px) {
  .header-links {
    margin-left: 7.50em;
  }
  h1 {
    font-size: 3.21em;
    line-height: 55px;
  }
  h3 {
    font-size: 2.50em;
    line-height: 43px;
  }
  .card {
    padding: 1.02em 1.02em;
    height: 22.35em;
  }
  .btn-default {
    padding: 0 0.75em;
    height: 3.27em;
  }
}