@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,500;1,600;1,700;1,800;1,900&display=swap");

.header-logo {
  width: 5.78em;
  height: 5.78em;
  border-radius: 50%;
}
.footer {
  flex: 0 0 2.7em;
  padding: 1.2em 0 1.1em;
}

.btn-gradient {
  background: transparent linear-gradient(90deg, #bc00ff 0%, #ff1aa1 100%) 0% 0%
    no-repeat padding-box;
  box-shadow: 0 0.7em 2.14em #94006733;
  color: #fff;
}

.btn-gradient:hover {
  background: transparent linear-gradient(110deg, #ff1aa1 35%, #bc00ff 100%) 0%
    0% no-repeat padding-box;
}

.btn-default {
  border-radius: 1.78em;
  font: normal normal normal 1em/1.5em Poppins;
  letter-spacing: 0;
  padding: 0 1.85em;
  height: 3.57em;
  font-weight: 500;
}
.header-options .btn {
  margin-left: 1.42em;
}
.btn-theme {
  width: 3.57em;
  aspect-ratio: 1/1;
  border-radius: 50%;
}
.header-links ul li {
  display: inline-flex;
  align-items: center;
  position: relative;
}
.header-links ul li:not(:last-child) {
  margin-right: 2em;
}
.header-links ul li a {
  font-size: 1.28em;
  line-height: 1.92em;
  letter-spacing: 0em;
  height: 3.07em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.header-links ul li a.readBtn {
  padding: 7px 16px;
  height: auto;
  position: relative;
  -webkit-transform: translate(0%, 0%);
  transform: translate(0%, 0%);
  overflow: hidden;
  position: relative;
}

.header-links ul li a.readBtn span {
  position: absolute;
}

.header-links ul li a.readBtn span:nth-child(1) {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  /* background: -webkit-gradient(linear, right top, left top, from(rgba(43, 8, 8, 0)), to(#d0f224));
  background: linear-gradient(to left, rgba(43, 8, 8, 0), #d0f224); */
  background: transparent linear-gradient(180deg, #ff8dd8 0%, #f817ab 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 0px 4px rgba(248, 23, 171, 0.5);
  -webkit-animation: 2s contactAnimateTop linear infinite;
  animation: 2s contactAnimateTop linear infinite;
}

.header-links ul li a.readBtn span:nth-child(2) {
  top: 0px;
  right: 0px;
  height: 100%;
  width: 2px;
  /* background: -webkit-gradient(linear, left bottom, left top, from(rgba(43, 8, 8, 0)), to(#d0f224));
    background: linear-gradient(to top, rgba(43, 8, 8, 0), #d0f224); */
  background: transparent linear-gradient(180deg, #ffffff 0%, #f8d704 100%) 0%
    0% no-repeat padding-box;
  -webkit-animation: 2s contactAnimateRight linear -1s infinite;
  animation: 2s contactAnimateRight linear -1s infinite;
}

.header-links ul li a.readBtn span:nth-child(3) {
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  /* background: -webkit-gradient(linear, left top, right top, from(rgba(43, 8, 8, 0)), to(#d0f224));
    background: linear-gradient(to right, rgba(43, 8, 8, 0), #d0f224); */
  background: transparent linear-gradient(180deg, #ffffff 0%, #f8d704 100%) 0%
    0% no-repeat padding-box;
  -webkit-animation: 2s contactAnimateBottom linear infinite;
  animation: 2s contactAnimateBottom linear infinite;
}

.header-links ul li a.readBtn span:nth-child(4) {
  top: 0px;
  left: 0px;
  height: 100%;
  width: 2px;
  /* background: -webkit-gradient(linear, left top, left bottom, from(rgba(43, 8, 8, 0)), to(#d0f224));
    background: linear-gradient(to bottom, rgba(43, 8, 8, 0), #d0f224); */
  background: transparent linear-gradient(180deg, #ff8dd8 0%, #f817ab 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 0px 4px rgba(248, 23, 171, 0.5);
  -webkit-animation: 2s contactAnimateLeft linear -1s infinite;
  animation: 2s contactAnimateLeft linear -1s infinite;
}

@keyframes contactAnimateTop {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}
@keyframes contactAnimateRight {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}
@keyframes contactAnimateBottom {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}
@keyframes contactAnimateLeft {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
}

.header-links ul li a.dashUnder.readBtn:hover::after {
  display: none;
}

.dashUnder:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 0.28em;
  background: transparent
    linear-gradient(
      90deg,
      #ff1aa1 0%,
      var(--unnamed-color-bc00ff) 47%,
      #1e0b3f26 100%
    )
    0% 0% no-repeat padding-box;
  background: transparent
    linear-gradient(90deg, #ff1aa1 0%, #bc00ff 47%, #1e0b3f26 100%) 0% 0%
    no-repeat padding-box;
  border-radius: 0.85em;
  width: 6.35em;
  transform: translateX(130%);
  opacity: 0;
  transition: all cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.2s;
}
.header-links ul li .dashUnder:hover:after {
  transform: translateX(0);
  opacity: 1;
}
.header-links ul .docLink svg {
  transition: all cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.2s;
}
.header-links ul .docLink:hover svg {
  opacity: 0;
}
.header-links ul {
  display: flex;
}

.header {
  padding: 2.25em 0 0 0;
  margin-bottom: -12px;
}
.header-links {
  margin-left: 9.85em;
}
.contentMain {
  overflow: hidden;
  padding-top: 0;
  padding-bottom: 3.22em;
  justify-content: center;
}
.docLink svg {
  position: absolute;
  left: 50%;
  pointer-events: none;
  transform: translateX(-50%);
}

/* footer */

.footerLink li:not(:last-child) {
  margin-right: 1.42em;
}
.copyText {
  margin: auto;
}
.copyText p {
  text-align: left;
  font-size: normal normal normal 1em;
  line-height: 1.78em;
  letter-spacing: 0.042em;
}
.freeFaucet a span {
  font-size: 1.14em;
  line-height: 1.78em;
  color: #ff1aa1;
  margin-right: 0.71em;
  vertical-align: middle;
}
/* footer */

/* Main */
.headerMain h3 {
  font-weight: normal;
}
.headerMain {
  position: relative;
}
.headerMain::after {
  opacity: 1;
  left: 50%;
  height: 0.57em;
  transform: translateX(-50%);
  bottom: -0.85em;
}
.mucards {
  margin-top: 5em;
  column-gap: 4.05em;
  padding: 0px 49px;
  position: relative;
}
.mucards::before {
  content: "";
  position: absolute;
  top: -62px;
  left: 50px;
  width: 1362px;
  height: 447px;
  background-image: url("../images/circles.png");
  background-repeat: no-repeat;
}
.card {
  max-width: 25%;
  flex: 1 1 25%;
  height: 26.25em;
  border: 2px solid var(--unnamed-color-e2e0e099);
  background: transparent linear-gradient(140deg, #e2e0e066 0%, #e2e0e00d 100%)
    0% 0% no-repeat padding-box;
  border: 2px solid #e2e0e099;
  border-radius: 10px;
  opacity: 1;
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
  background-attachment: fixed;
  padding: 1.42em 1.02em;
  padding-top: 55px;
  max-width: 19.5em;

  position: relative;
  text-align: center;
}
.muCoin {
  width: 10.21em;
  height: 10.21em;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.42em;
  margin-inline: auto;
  background-color: #d2d2d2;
  border-radius: 50%;
}
.muCoin img {
  max-width: 6.92em;
}
.muGold .muCoin {
  background: #f4d974;
}
.muOre .muCoin {
  background: #ffbfa2;
}
.muVault .muCoin {
  background: #9099b5;
}

.muAmtComp {
  height: 68px;
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 0.85em;
  padding-right: 0.85em;
  padding-top: 1em;
  padding-bottom: 0.85em;
  border-radius: 0 5px 5px 0;
  position: relative;
  overflow: hidden;
}

.muAmtComp:after {
  position: absolute;
  content: "";
  left: 0;
  width: 2px;
  height: 100%;
  background: #d2d2d2;
}
.muGold .muAmtComp:after {
  background-color: #f4d974;
}
.muOre .muAmtComp:after {
  background-color: #ffbfa2;
}
.muVault .muAmtComp:after {
  background-color: #9099b5;
}
.muAmtComp h5 {
  font-size: 1em;
}
.muAmtComp span {
  color: #d6d6d6;
  letter-spacing: 0.48px;
  font-size: 0.85em;
  line-height: 1.28em;
}
.muAmtComp span.valueSpan {
  font-size: 0.85em;
}
.muAmtComp span.valuationSpan {
  color: #fff;
}
.card footer .btn {
  font-size: 0.85em;
  width: 100%;
  font-weight: 500;
}
.card footer {
  margin-top: 1.42em;
}
.btn-double {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  text-align: center;
}
.topDeck em {
  max-width: 165px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  vertical-align: middle;
}

button.btn.btn-gradient.btn-default.disabled {
  cursor: not-allowed;
}
.topDeck figure {
  display: inline-block;
  margin-right: 3px;
  line-height: 1;
}

.header-options .btn-double {
  text-align: center;
  font: normal normal normal 12px/18px Poppins;
  letter-spacing: 0px;
  height: 50px;
  border-radius: 25px;
}
.header-options .btn-double .bottomDeck,
.header-options .btn-double .topDeck {
  padding: 0 5px;
}
.header-options .btn-double .bottomDeck {
  padding-top: 3px;
  border-top: 1px solid;
}
.header-options .btn-double .topDeck {
  padding-bottom: 3px;
}

.comingSoon {
  margin-top: 48px;
}

.headerBtnsCollections {
  position: relative;
}

.noBalance {
  padding: 1.74em 1.5em;
}

.displayingValue {
  position: relative;
}
.displayingValueWraper:hover .currentValueDropdown {
  display: block;
}

.noBalance.currentValueDropdown p:last-child {
  margin-bottom: 0;
}

.haveBalance {
  padding: 0.2em 1.1em;
}

.individualListingLi {
  display: flex;
  align-items: center;
  padding: 0.9em 0.7em;
}

.individualListingLi.disconBtn:hover,
.individualListingLi.totals:hover {
  background: #000b2c;
}

.individualListingLi figure {
  width: 29px;
  height: 29px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 11px;
}

.dropdownIcons {
  width: 100%;
  height: 100%;
}
.dropsownInfoWraper {
  width: calc(100% - 40px);
}

.currentValueDropdown {
  display: none;
}

.totalValue {
  font-size: 1em;
  font-weight: 500;
}

.totalValuation {
  font-size: 1em;
  margin-left: auto;
  font-weight: 500;
}

.individualListingLi.totals {
  border-bottom: none;
}

.displayingValueWraper {
  position: relative;
}

.disconnectedBtns {
  border-radius: 20px;
  padding: 1em;
  width: 12em;
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border: none;
}
.disconBtn {
  padding-top: 0;
}

.modalHedading,
.modalSubHedading {
  width: 100%;
  display: flex;
  align-items: center;
}
.modalBody {
  margin: 40px 0 10px 0;
}

.currencyIcon {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.currencyIcon span {
  margin-left: 10px;
  font-size: 1em;
  color: #9099b5;
}

.inputFieldSection {
  margin-right: 20px;
  width: auto;
  min-width: 42%;
}

.inputFieldSection input {
  border: none;
  box-shadow: none;
  font-size: 1em;
  width: 100%;
}

/* Chrome, Safari, Edge, Opera */
.inputFieldSection input::-webkit-outer-spin-button,
.inputFieldSection input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.inputFieldSection input[type="number"] {
  -moz-appearance: textfield;
}

.inputFieldSection input::-webkit-input-placeholder {
  /* Edge */
  color: #505a7b;
  font-size: 0.85em;
}

.inputFieldSection input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #505a7b;
  font-size: 0.85em;
}

.inputFieldSection input::placeholder {
  color: #505a7b;
  font-size: 0.85em;
}

.maxButton {
  border-radius: 25px;
  opacity: 1;
  font-size: 1em;
  color: #fff;
  cursor: pointer;
  padding: 0.64em 2.35em;
  box-shadow: none;
  border: none;
  outline: none;
}
.btnMax {
  margin-left: auto;
}

.alterBtnRow {
  width: 100%;
  text-align: center;
  display: flex;
  margin: 20px 0;
  align-items: center;
}
.alterBtnRow img {
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  width: 46px;
  height: 46px;
}

.currencyIcon img {
  width: 28px;
  height: 28px;
}

.modal-content.succesFailResult {
  background-image: url("../images/success.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 653px;
  padding: 2em;
}

#succesFailClose {
  margin-bottom: 0;
}

.modalSuccess {
  text-align: center;
}

.modalSuccess h1 {
  color: #fff;
  font-size: 2.85em;
  margin-bottom: 5px;
  line-height: 1.28em;
}

.modalSuccess p {
  font-size: 1.71em;
  letter-spacing: 0.05em;
  color: #c0c0c0;
  line-height: 1.58em;
  margin-bottom: 20px;
}

.hilighted {
  color: #ff1aa1;
  font-weight: 500;
}

.close-button {
  float: right;
  text-align: center;
  cursor: pointer;
  margin-left: auto;
  margin-bottom: 20px;
}

.close-button img {
  width: 1em;
  height: 1em;
}

.modalSuccess .swapBtn {
  width: 320px;
}
.alertIcons {
  margin-bottom: 2.85em;
}

.failureModal h1 {
  font-size: 2.7em;
  margin-bottom: 1.425em;
}

.failureModal p {
  color: #d2d2d2;
  font-weight: 400;
  font-size: 1.58em;
  margin-bottom: 2.2em;
}

.failureModal .swapBtn.btn-gradient {
  margin-bottom: 2.35em;
}
.freeFaucet svg {
  width: 40px;
  height: 30px;
}
.modalBody .alterBtnRow .swapIcons {
  margin: 0 auto;
  cursor: pointer;
}

button.btn.btn-gradient.btn-default.comingSoonBtn.disabled {
  opacity: 0.72;
}

@media only screen and (max-width: 1650px) {
  h1 {
    font-size: 3.21em;
    line-height: 50px;
  }
  .contentMain {
    padding-top: 1.22em;
    padding-bottom: 3.22em;
  }
}

@media only screen and (max-width: 1550px) {
  h1 {
    font-size: 3.21em;
    line-height: 50px;
  }
  .contentMain {
    padding-top: 0.22em;
    padding-bottom: 1.22em;
  }
}
@media only screen and (max-width: 1350px) {
  .card footer .btn {
    font-size: 0.7em;
  }
}
@media only screen and (max-width: 1255px) {
  .header-links ul li a {
    font-size: 0.85em !important;
  }
  .header-links {
    margin-left: 2.5em !important;
  }
  .btn.btn-dark.btn-default {
    font-size: 0.75em !important;
  }
  .header-links ul li:not(:last-child) {
    margin-right: 1.75em !important;
  }
}

@media only screen and (max-width: 1150px) {
  .card footer .btn {
    font-size: 0.65em;
  }
}

span.conditioWraper {
  display: contents;
}

span.valueSpan.coming {
  font-size: 14px;
  font-weight: 700;
}

.approvingSuccess {
  text-align: center;
  margin: 15px 0;
  width: 100%;
}

.disabledClick {
  pointer-events: none;
}

.buttonWrapers {
  width: 100%;
  display: flex;
}

.muGoldValues {
  width: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: auto;
  right: 0;
  text-align: center;
  padding: 2px 12px;
}

.muGoldValues span {
  font-size: 9.5px;
}

.textMu.lefts {
  margin-right: auto;
}
.textMu.rights {
  margin-left: auto;
}

.addToWalet {
  position: absolute;
  bottom: -40px;
  left: 0;
  right: 0;
  font-size: 16px;
  letter-spacing: 0px;
  color: #ff1aa1;
  opacity: 1;
  cursor: pointer;
  text-decoration: underline;
}

.miningInfo {
  width: 100%;
  position: absolute;
  text-align: center;
  left: 0;
  top: 0;
  padding: 7px 5px;
  border-radius: 8px 8px 0px 0px;
}

.logoutTexts {
  margin: 0 !important;
  padding-top: 25px;
}

.modal-content {
  padding: 20px 39px 40px 39px;
}

#notAccepted {
  pointer-events: none;
  opacity: 0.4;
}

iframe {
  z-index: -1 !important;
}

.tooltip_up {
  position: relative;
}
.tooltip_up p {
  display: flex;
  align-items: center;
  gap: 5px;
}
.tooltip_up .tooltip {
  transition: 0.3s;
  content: "";
  position: absolute;
  top: 0;
  left: -122px;
  position: absolute;
  top: -90px;
  color: #9099b5;
  background-color: #151f3e;
  border-radius: 12px;
  width: 260px;
  padding: 10px 20px;
  /* opacity: 0; */
  display: none !important;
  text-align: center;
  font-size: 14px !important;
  z-index: 5;
}
.tooltip_up .tooltip::after {
  content: "";
  position: absolute;
  bottom: -15px;
  left: 47%;
  /* width: 30px;
  height: 30px; */
  /* z-index: 2; */
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;

  border-top: 20px solid #151f3e;
  /* transform: scaleX(0.5) translateX(-50%) rotate(45deg); */
}
.tooltip_up img {
  cursor: pointer;
}
.tooltip_up .imgHover:hover .tooltip {
  /* opacity: 1; */
  display: block !important;
}
